<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="row align-items-center">
            <div class="col">
                <h3 class="mb-0">{{cardTitle ?? 'Table Title'}}</h3>
            </div>
            </div>
        </div>
        <div class="table-responsive">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableOneButtonComponent',
    props: [
        'cardTitle',
        'buttonNameOne'
    ],
}
</script>