<template>
    <div class="card bg-default">
        <div class="card-header bg-transparent">
            <div class="row align-items-center">
            <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">{{cardSubTitle ?? 'Sub Title'}}</h6>
                <h5 class="h3 text-white mb-0">{{cardTitle ?? 'Title'}}</h5>
            </div>
            </div>
        </div>
        <div class="card-body bg-white">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TwoButtonColorCardComponent',
    props: [
        'cardTitle',
        'cardSubTitle',
        'buttonNameOne',
        'buttonNameTwo'
    ],
}
</script>